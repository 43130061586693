import React, { useContext, useEffect } from 'react';
import { UserContext } from "../withUser/withUser";
import config from '../config.js'
import "./logout.css"

export function Logout() {
    const { setCurrUser } = useContext(UserContext)
    
    useEffect(() => {
        window.gapi.auth2.init({ client_id: config.GAPI_CLIENT_ID, hosted_domain: 'gauntlet.network' }).then((gauth) => { gauth.signOut() });
    })

    return (
        <div>
            <button className="myButton" onClick = {() => setCurrUser(null)}>Logout</button>
        </div>
    )
}