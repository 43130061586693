import React, { useEffect } from 'react';
import config from '../config.js'

interface Props {
    onSuccess: (gauth: gapi.auth2.GoogleAuth) => void,
}

function Login(props: Props) {
    const loginRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        window.gapi.load('auth2:signin2', () => {
            window.gapi.auth2.init({
                client_id: config.GAPI_CLIENT_ID,
                hosted_domain: 'gauntlet.network'
            }).then((gauth) => {
                window.gapi.signin2.render(loginRef.current, {
                    'scope': 'profile email',
                    'width': 250,
                    'height': 50,
                    'longtitle': false,
                    'theme': 'dark',
                    'onsuccess': () => {
                        props.onSuccess(gauth)
                    },
                    'onfailure': () => {
                        console.log("Sign-in failed")
                    }
                })
            })
        })
    })

    return (
        <div className="Login" ref={loginRef}/>
    )
}

export default Login; 